<template>
  <div>
    <!-- loading animation -->
    <template v-if="!loaded">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!-- data not found -->
    <template v-else-if="hasNoResources">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-alert dense type="info">
              <v-row align="center">
                <v-col class="grow">Please wait for a while.</v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-container style="height: 100%">
        <v-row>
          <v-col>
            <div>
              Open data source
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" id="open_datasource_guide">
                    mdi-information-outline
                  </v-icon>
                </template>
                Show tour guide
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="pb-0">
            <v-select :items="items" v-model="select" label="Field"></v-select>
          </v-col>
          <v-col cols="8" class="pb-0">
            <div class="d-flex justify-end">
              <!-- Add this div with justify-end class -->
              <v-btn class="mr-2" color="primary" rounded @click="onSave"
                >Save</v-btn
              >
              <v-btn color="primary" rounded @click="onMerge">Merge</v-btn>
            </div>
          </v-col>
          <v-col cols="2" class="pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-tabs v-model="tab" show-arrows>
              <v-tab v-for="tab in tabs" :key="tab.icon">
                <v-icon size="20" class="me-3">
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-tabs-items v-model="tab">
            <v-col v-if="tab === 0" class="px-0">
              <v-col>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="openData"
                  :items-per-page="10"
                  :single-select="singleSelect"
                  :search="search"
                  show-select
                  item-key="id"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <div class="d-flex">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="indigo"
                            v-bind="attrs"
                            v-on="on"
                            @click="onView(item)"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("view") }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-col>
            <v-col v-if="tab === 1" class="px-0">
              <v-col>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="selected"
                  :items-per-page="10"
                  :single-select="singleSelect"
                  :search="search"
                  show-select
                  item-key="id"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <div class="d-flex">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="indigo"
                            v-bind="attrs"
                            v-on="on"
                            @click="onView(item)"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("view") }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-col>
          </v-tabs-items>
        </v-row>
      </v-container>
    </template>

    <!-- merge dialog -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Would you like to merge dataset with the following open source data?
          </span>
          <span>*It would probably takes few minutes.</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Title</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in selected" :key="item.id">
                  <td>{{ item.title }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="onConfirmMerge">
            Save and Merge
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import data from "@/models/data";
// import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import axios from "axios";

export default {
  name: "DatasetGroupsOpenSourceData",
  data() {
    return {
      search: "",
      singleSelect: false,
      loaded: true,
      hasNoResources: null,
      items: ["Economics"],
      selected: [],
      select: "Economics",
      headers: [],
      openData: [],
      url: "http://192.168.50.3:9012/grocery/v1/",
      dialog: false,
      open_source_data_id: null,
      tab: 0,
      tabs: [
        { title: "Data", icon: "mdi-database-outline" },
        { title: "Selected", icon: "mdi-checkbox-marked-outline" },
      ],
    };
  },
  components: {},
  computed: {
    loading() {
      return this.$store.state.loader.loading;
    },
  },
  methods: {
    onSearch() {
      // Call API to get searched open data
      // Temp: Use static data to show

      this.headers = [
        // {
        //   text: "Name",
        //   align: "start",
        //   sortable: false,
        //   value: "id",
        // },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
          width: "500px",
        },
        { text: "Data source", value: "source", width: "150px" },
        { text: "Frequency", value: "frequency", width: "150px" },
        { text: "Start time", value: "started_at", width: "150px" },
        { text: "End time", value: "ended_at", width: "150px" },
        { text: "Update time", value: "updated_at", width: "150px" },
        {
          text: this.$t("action"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ];

      this.getList();

      //   this.openData = [
      //   {
      //   id: "00XAPMBGM086NEST",
      //   source: "FRED",
      //   title: "Harmonized Index of Consumer Prices: All Items Excluding Mainly Administered Prices for Bulgaria",
      //   frequency: "Monthly",
      //   started_at: "2000-12-01",
      //   ended_at: "2022-12-01",
      //   updated_at: "2023-03-05 08:09:51+00:00"
      // },
      // {
      //   id: "ARGCPALTT01IXNBM",
      //   source: "FRED",
      //   title: "Consumer Price Index: All items: Total: Total for Argentina",
      //   frequency: "Monthly",
      //   started_at: "2016-12-01",
      //   ended_at: "2022-12-01",
      //   updated_at: "2023-03-05 08:09:30+00:00"
      // },
      // {
      //   id: "AUSCPIALLQINMEI",
      //   source: "FRED",
      //   title: "Consumer Price Index: All Items: Total: Total for Australia",
      //   frequency: "Quarterly",
      //   started_at: "1960-01-01",
      //   ended_at: "2022-10-01",
      //   updated_at: "2023-03-05 08:09:33+00:00"
      // },
      // {
      //   id: "AUTCPIALLMINMEI",
      //   source: "FRED",
      //   title: "Consumer Price Index: All Items: Total: Total for Austria",
      //   frequency: "Monthly",
      //   started_at: "1960-01-01",
      //   ended_at: "2022-12-01",
      //   updated_at: "2023-03-05 08:09:35+00:00"
      // }
      //   ];
    },
    async onSave() {
      // Call API to add open source data
      let res = null;
      let ids = [];
      this.selected.forEach((o) => {
        ids.push({
          id: o.id,
          source: o.source,
        });
      });
      try {
        const payload = {
          datasetGroupId: this.$route.params.id,
          datasetId: this.datasetId,
          ids: ids,
        };
        res = await data.updateOpenSourceDatasetId(payload);
      } catch (error) {
        console.log(error);
      }

      if (res.status !== 200) {
        this.$dialog.message.warning(this.$t("addOpenSourceDataError"), {
          position: "top",
        });
      } else if (res.status === 200) {
        this.$dialog.message.success(this.$t("addOpenSourceDataSuccess"), {
          position: "top",
        });
      } else {
        this.$dialog.message.error(this.$t("systemError"), {
          position: "top",
        });
      }
      // const id = this.$route.params.id;
      this.$emit("update:is-model-create-active", false);

      // this.$router.push(`/datasetGroups/${id}/overview`);

      // //PETER
      // this.$router.push({
      //   path: `/datasetGroups/${id}/overview`,
      //   query: {
      //     isSkipOpenData: true,
      //     isStartCausality: true,
      //   },
      // });
    },
    onView(item) {
      // const id = this.$route.params.id;
      const indexId = item.id;
      const source = item.source;
      console.log(indexId);
      console.log(source);
      console.log(item);
      // this.$router.push(`/datasetGroups/${id}/open-source-data-view/${indexId}/${source}`);
      this.$router.push({
        name: "dataset-groups-tab-open-source-view",
        params: { id: indexId, source: source },
      });
    },
    async getList() {
      axios
        .get(this.url + `categories/economy?page=1&size=500`)
        .then((res) => {
          if (res.status === 200) {
            this.openData = res.data.data;
            // console.log(this.openData);
            const selected = [];
            this.open_source_data_id.forEach((o) => {
              // console.log("o", o);
              // console.log("this.openData", this.openData);
              let data = _.find(this.openData, (openData) => {
                return openData.id === o.id;
              });
              // console.log("data", data);
              if (data) {
                selected.push(data);
              }
            });
            // console.log("selected", selected);
            this.selected = selected;
          }
        })
        .catch(() => {
          console.log("Failure");
        });
    },
    async getDatasets() {
      const id = this.$route.params.id;
      try {
        const res = await data.getDatasets(id);
        let items = [];
        res.forEach((dataset) => {
          const obj = {
            id: dataset.id,
            name: dataset.name,
            description: dataset.description,
            url: dataset.url,
            category_id: dataset.category_id,
          };
          items.push(obj);
        });
        items = _.sortBy(items, ["category_id"]);
        let items_filtered = items.filter((item) => item.name == "data");
        // console.log("items", items);
        // console.log("items_filtered", items_filtered);
        this.datasetId = items_filtered[0].id;
      } catch (error) {
        console.log("error", error);
      }
      this.loaded = true;
    },
    onMerge() {
      this.dialog = true;
    },
    onClose() {
      this.dialog = false;
    },
    async onConfirmMerge() {
      const payload = {
        datasetGroupId: this.$route.params.id,
        datasetId: this.datasetId,
        skipped: false,
      };
      // onSave data first
      await this.onSave();

      const res = await data.createMergedDataset(payload);
      // console.log("res", res);
      if (res.status === 202) {
        this.$dialog.message.success(this.$t("addOpenSourceDataSuccess"), {
          position: "top",
        });
        const id = this.$route.params.id;
        this.$emit("update:is-model-create-active", false);

        //PETER
        this.$router.push({
          path: `/datasetGroups/${id}/overview`,
        });
      } else {
        this.$dialog.message.error(this.$t("systemError"), {
          position: "top",
        });
      }
    },
    async getOpenSourceDataId() {
      const payload = {
        datasetGroupId: this.$route.params.id,
        datasetId: this.datasetId,
      };
      try {
        const res = await data.getOpenSourceDataId(payload);
        // console.log(
        //   "getOpenSourceDataId",
        //   res.DatasetOpenSourceDatum.open_source_data_id
        // );
        const open_source_data_id =
          res.DatasetOpenSourceDatum.open_source_data_id;
        this.open_source_data_id = open_source_data_id;
      } catch (error) {
        this.open_source_data_id = [];
      }
    },
  },
  async created() {
    // this.getList();
    await this.getDatasets();
    await this.getOpenSourceDataId();
    await this.onSearch();
  },
};
</script>

<style scope></style>
