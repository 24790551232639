var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loaded)?[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)]:(_vm.hasNoResources)?[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"dense":"","type":"info"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v("Please wait for a while.")])],1)],1)],1)],1)],1)]:[_c('v-container',{staticStyle:{"height":"100%"}},[_c('v-row',[_c('v-col',[_c('div',[_vm._v(" Open data source "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"id":"open_datasource_guide"}},on),[_vm._v(" mdi-information-outline ")])]}}])},[_vm._v(" Show tour guide ")])],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Field"},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","rounded":""},on:{"click":_vm.onSave}},[_vm._v("Save")]),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.onMerge}},[_vm._v("Merge")])],1)]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.icon},[_c('v-icon',{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.tab === 0)?_c('v-col',{staticClass:"px-0"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.openData,"items-per-page":10,"single-select":_vm.singleSelect,"search":_vm.search,"show-select":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.onView(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view")))])])],1)]}}],null,false,1107109192),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e(),(_vm.tab === 1)?_c('v-col',{staticClass:"px-0"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.selected,"items-per-page":10,"single-select":_vm.singleSelect,"search":_vm.search,"show-select":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.onView(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view")))])])],1)]}}],null,false,1107109192),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e()],1)],1)],1)],_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" Would you like to merge dataset with the following open source data? ")]),_c('span',[_vm._v("*It would probably takes few minutes.")])]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Title")])])]),_c('tbody',_vm._l((_vm.selected),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.title))])])}),0)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onClose}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onConfirmMerge}},[_vm._v(" Save and Merge ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }